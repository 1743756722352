import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import {
	HighlightedAttribute,
	getPopoverLabel,
	LocationPopoverContent,
	isValidFuelType,
	isInTransit,
	getInTransitLabel,
	getInTransitNoteLabel,
	QuickNotesButton
} from 'wsm-highlighted-attribute';
import { highlightedAttrList } from '../../../global-constants';
import { getAttributeValue } from '../../../utilities/highlightedAttributes';
import { selectIsGridLayout } from '../../../utilities/layout';
import { checkConcaternateAttr } from '../../../utilities/concatenateAttributeCheck';

const highlightedAttributeFactory = ({
	type = '',
	content = '',
	popoverContent,
	popoverAria = '',
	isGridLayout,
	useSimplifiedListing,
	badgeType = 'default'
}) => {
	return (
		<HighlightedAttribute
			popoverContent={popoverContent}
			type={type}
			badgeClasses={[
				badgeType,
				'mr-2',
				useSimplifiedListing ? 'my-3' : '',
				isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
			]}
			popoverAria={popoverAria}
		>
			{content}
		</HighlightedAttribute>
	);
};

const HighlightedAttributeContainer = ({
	accountInfo,
	highlightedAttributes,
	hasInTransitBadge,
	isNewCarBoost,
	isSpotlightedVehicle
}) => {
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const { pageAlias } = useSelector((state) => state.widgetInfo);
	const { enableLocationHighlight } = usePrefs();
	const labels = useLabels();
	const { deviceType, locale } = useRequestData();
	const isMobile = deviceType === 'MOBILE';

	let fuelTypeAttribute;
	const quickNotesAttrs = [];
	const fuelType = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.FUEL_TYPE
	);
	if (isValidFuelType(fuelType)) {
		const [fuelTypePopover, fuelTypeNote] = getPopoverLabel({
			fuelType,
			labels
		});
		setNewRelicCustomAttribute('fuel-highlighted-attribute', true);
		fuelTypeAttribute = highlightedAttributeFactory({
			type: 'fuelType',
			content: fuelType,
			popoverContent: fuelTypePopover,
			isGridLayout,
			useSimplifiedListing
		});
		quickNotesAttrs.push({
			type: 'fuelType',
			highlightedAttr: fuelTypeAttribute,
			note: fuelTypeNote
		});
	}

	let locationAttribute;
	const account = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.LOCATION
	);
	if (
		(enableLocationHighlight === 'true' || useSimplifiedListing) &&
		account?.name
	) {
		const cleanLocationTitle = window?._?.unescape(account.name);
		const popoverAria = `${labels.get(
			'THIS_VEHICLE_IS_LOCATED_AT'
		)} ${cleanLocationTitle}`;
		let locationPopoverContent;
		if (Object.keys(account.address).length !== 0 || account.phone) {
			locationPopoverContent = (
				<LocationPopoverContent
					phone={account.phone}
					id={account.id}
					address={account.address}
					isMobile={isMobile}
				/>
			);
		}

		locationAttribute = highlightedAttributeFactory({
			type: 'location',
			content: cleanLocationTitle,
			popoverContent: locationPopoverContent,
			popoverAria,
			isGridLayout,
			useSimplifiedListing
		});
	}

	let inTransitAttribute;
	let inTransitAttributeNote;
	const inTransitData = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.ESTIMATED_DELIVERY_DATE
	);

	if (isInTransit(inTransitData?.status)) {
		const { estimatedDeliveryDate } = inTransitData;
		const inTransitText = getInTransitLabel({
			estimatedDeliveryDate,
			locale,
			labels
		});

		inTransitAttribute = highlightedAttributeFactory({
			type: 'inTransit',
			content: inTransitText,
			popoverContent: inTransitText,
			isGridLayout,
			useSimplifiedListing
		});
		inTransitAttributeNote = getInTransitNoteLabel(
			accountInfo?.name,
			labels
		);
		quickNotesAttrs.push({
			type: 'inTransit',
			highlightedAttr: inTransitAttribute,
			note: inTransitAttributeNote
		});
	}

	let odometerAttribute;
	let odometerAttributeNote;
	const odometer = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.ODOMETER
	);

	/**
	 * When vehicle don't have odometer or it's value is 0, the value is still return with odometer unit 'miles', 'km', etc.
	 * Need use regex to check and only display highlighted odometer have formated odometer value and odometer unit
	 * The value is valid if contains odometer value from 1 to 3 digits or if number have more than 3 digits,
	 * we have comma for each 3 degits group.
	 */
	const isValidOdometer = checkConcaternateAttr(
		highlightedAttrList.ODOMETER,
		odometer
	);

	if (useSimplifiedListing && isValidOdometer) {
		const odometerPopoverContent = getAttributeValue(
			highlightedAttributes,
			highlightedAttrList.ODOMETER,
			true
		);

		odometerAttribute = highlightedAttributeFactory({
			type: 'odometer',
			content: odometer,
			popoverContent: odometerPopoverContent,
			isGridLayout,
			useSimplifiedListing
		});
		odometerAttributeNote = labels.get('HIGHLIGHTED_ATTR_ODOMETER_EXPLAIN');

		quickNotesAttrs.push({
			type: 'odometer',
			highlightedAttr: odometerAttribute,
			note: odometerAttributeNote
		});
	}

	let certifiedAttribute;
	const certified = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.CERTIFIED
	);

	if (certified) {
		certifiedAttribute = highlightedAttributeFactory({
			type: 'certified',
			content: certified,
			popoverContent: certified,
			isGridLayout,
			useSimplifiedListing
		});
	}

	let vehicleTypeAttribute;
	if (isNewCarBoost) {
		const newLabel = labels.get('NEW');
		vehicleTypeAttribute = highlightedAttributeFactory({
			type: newLabel,
			popoverContent: newLabel,
			content: newLabel,
			isGridLayout,
			useSimplifiedListing
		});
	} else if (pageAlias.includes('_AUTO_ALL')) {
		const vehicleType = getAttributeValue(
			highlightedAttributes,
			highlightedAttrList.CONDITION
		);
		if (vehicleType) {
			vehicleTypeAttribute = highlightedAttributeFactory({
				type: 'condition',
				content: vehicleType,
				popoverContent: vehicleType,
				isGridLayout,
				useSimplifiedListing
			});
		}
	}

	let vehicleTransferAttribute;
	let vehicleOnSiteAttribute;
	// Onsite or Offsite highlighted attribute should only be shown if the vehicle is not in transit.
	if (!hasInTransitBadge && !isInTransit(inTransitData?.status)) {
		const vehicleTransfer = getAttributeValue(
			highlightedAttributes,
			highlightedAttrList.TRANSFER
		);
		if (vehicleTransfer) {
			vehicleTransferAttribute = highlightedAttributeFactory({
				type: vehicleTransfer,
				content: vehicleTransfer,
				popoverContent: vehicleTransfer,
				isGridLayout,
				useSimplifiedListing
			});
		}

		const vehicleOnSite = getAttributeValue(
			highlightedAttributes,
			highlightedAttrList.ONSITE
		);
		if (vehicleOnSite) {
			vehicleOnSiteAttribute = highlightedAttributeFactory({
				type: vehicleOnSite,
				popoverContent: vehicleOnSite,
				content: vehicleOnSite,
				isGridLayout,
				useSimplifiedListing,
				badgeType: 'in-stock'
			});
		}
	}

	let vehicleSpotlightAttribute;
	if (isSpotlightedVehicle) {
		const featuredLabel = labels.get('FEATURED');
		vehicleSpotlightAttribute = highlightedAttributeFactory({
			type: featuredLabel,
			popoverContent: featuredLabel,
			content: featuredLabel,
			isGridLayout,
			useSimplifiedListing,
			badgeType: 'hotcars'
		});
	}

	let classificationAttribute;
	const classification = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.CLASSIFICATION
	);
	if (classification && classification.toLowerCase() !== 'primary') {
		classificationAttribute = highlightedAttributeFactory({
			type: 'classification',
			content: classification,
			isGridLayout,
			useSimplifiedListing
		});
	}

	const isEnabled = [
		certifiedAttribute,
		classificationAttribute,
		fuelTypeAttribute,
		inTransitAttribute,
		locationAttribute,
		odometerAttribute,
		vehicleOnSiteAttribute,
		vehicleSpotlightAttribute,
		vehicleTransferAttribute,
		vehicleTypeAttribute
	].some((attr) => !!attr);

	if (isEnabled) {
		return (
			<QuickNotesButton quickNotesAttrs={quickNotesAttrs}>
				<div
					className={setClassNames([
						'vehicle-card-highlight',
						useSimplifiedListing ? 'mb-0' : 'mb-3',
						'align-self-start',
						'd-flex',
						'flex-wrap'
					])}
				>
					{vehicleSpotlightAttribute}
					{vehicleOnSiteAttribute}
					{vehicleTransferAttribute}
					{inTransitAttribute}
					{classificationAttribute}
					{certifiedAttribute}
					{vehicleTypeAttribute}
					{odometerAttribute}
					{locationAttribute}
					{fuelTypeAttribute}
				</div>
			</QuickNotesButton>
		);
	}
	return null;
};

HighlightedAttributeContainer.propTypes = {
	accountInfo: PropTypes.shape({
		name: PropTypes.string
	}),
	hasInTransitBadge: PropTypes.bool,
	highlightedAttributes: PropTypes.arrayOf(PropTypes.shape({})),
	isNewCarBoost: PropTypes.bool,
	isSpotlightedVehicle: PropTypes.bool
};

export default HighlightedAttributeContainer;
